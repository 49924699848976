import { WDSThemeProvider } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context';
import { updateShowGrid } from '@local/webviz/dist/context/snapshots/camera';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useContext, useState } from 'react';

import { ColorPicker } from 'src/components/ColorPicker/ColorPicker';
import { DEFAULT_MODEL_SETTINGS, METRES_UNIT_VALUE, PROJECT_DISTANCE_UNITS } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { GtmModel, GtmModelSettings } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelSettings,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';

import {
    BACKGROUND_LABEL,
    MODEL_TITLE,
    OFF_LABEL,
    ON_LABEL,
    UNITS_LABEL,
    XYZ_AXIS_LABEL,
} from './ModelSettingsPanel.constants';
import { useStyles } from './ModelSettingsPanel.styles';

export function ModelSettingsPanel() {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const { classes } = useStyles();

    return (
        <Box className={classes.root}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
                        {MODEL_TITLE}
                    </DialogTitle>
                    <Divider />
                    <SettingsSection />
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}

function SettingsSection() {
    const { classes } = useStyles();
    return (
        <Stack direction="column" className={classes.settingsSection}>
            <BackgroundColorInput />
            <DistanceUnitsInput />
            <XYZAxisToggle />
        </Stack>
    );
}

function getUpdatedSettings(model: GtmModel, newSettings: Partial<GtmModelSettings>) {
    return {
        ...(model.settings ?? DEFAULT_MODEL_SETTINGS),
        ...newSettings,
    };
}

function BackgroundColorInput() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();
    const [openColorPicker, setOpenColorPicker] = useState(false);

    const handleColorPickerOnSave = (color: string) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, { backgroundColor: color });

        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    // TODO (GEOM-547): Change background based on the color picked
    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {BACKGROUND_LABEL}
            </Typography>
            <Stack direction="row" width="145px" className={classes.propertyInput}>
                <Box sx={{ position: 'relative' }}>
                    <IconButton
                        component="label"
                        size="medium"
                        onClick={() => {
                            setOpenColorPicker(true);
                        }}
                    >
                        <Icon
                            sx={{
                                borderRadius: '4px',
                                backgroundColor:
                                    currentModelSettings?.backgroundColor ??
                                    DEFAULT_MODEL_SETTINGS.backgroundColor,
                                border: '1px solid #FAFCFF8F',
                            }}
                        />
                    </IconButton>
                    <ColorPicker
                        sx={{ position: 'absolute', right: '140px', top: '-32px' }}
                        open={openColorPicker}
                        onSave={handleColorPickerOnSave}
                        onClose={() => {
                            setOpenColorPicker(false);
                        }}
                        initialColor={
                            currentModelSettings?.backgroundColor ??
                            DEFAULT_MODEL_SETTINGS.backgroundColor
                        }
                    />
                </Box>
                <TextField
                    variant="outlined"
                    size="small"
                    InputProps={{ className: classes.colorTextFieldInput }}
                    value={
                        currentModelSettings?.backgroundColor ??
                        DEFAULT_MODEL_SETTINGS.backgroundColor
                    }
                />
            </Stack>
        </Stack>
    );
}

function DistanceUnitsInput() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();

    const handleOnChange = async (e: SelectChangeEvent<string>) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, { units: e.target.value });
        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {UNITS_LABEL}
            </Typography>
            <Select
                variant="outlined"
                size="small"
                value={currentModelSettings?.units ?? METRES_UNIT_VALUE}
                sx={{ minWidth: '145px' }}
                onChange={handleOnChange}
            >
                {PROJECT_DISTANCE_UNITS.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                        <Typography variant="body2">{unit.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
}

function XYZAxisToggle() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const isChecked = currentModelSettings?.showXyzAxis ?? false;
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();
    const { setXyzStateDirectly } = useBaseXyz();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!currentModel) {
            return;
        }

        // We could also listen to this state change in the XYZ entity if it
        // could change elsewhere... but this is enough for now.
        setXyzStateDirectly(updateShowGrid(event.target.checked));

        const modifiedSettings = getUpdatedSettings(currentModel, {
            showXyzAxis: event.target.checked,
        });
        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    const label = isChecked ? ON_LABEL : OFF_LABEL;

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {XYZ_AXIS_LABEL}
            </Typography>
            <Stack direction="row" width="145px" className={classes.propertyInput}>
                <FormControlLabel
                    className={classes.axisToggle}
                    label={<Typography variant="body2">{label}</Typography>}
                    color="primary"
                    control={<Switch size="small" checked={isChecked} onChange={handleChange} />}
                />
            </Stack>
        </Stack>
    );
}
