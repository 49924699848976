import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import ShowChartOutlined from '@mui/icons-material/ShowChartOutlined';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
    ChangeEvent,
    Dispatch,
    KeyboardEvent,
    MouseEvent,
    SetStateAction,
    useMemo,
    useState,
} from 'react';

import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { GtmModel, isGtmAnalyticalModel } from 'src/gtmProject';
import { GtmAnalyticalModel } from 'src/gtmProject/Project.types';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deleteModelInCurrentProject,
    deselectCurrentModelSelectedObject,
    setCurrentModelName,
} from 'src/store/project/projectSlice';
import {
    selectCurrentProjectModelNames,
    selectCurrentProjectModels,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setAllDetectionSettings } from 'src/store/ui/detectionSettings';
import { detectionSettingsFromAnalyticalModelSettings } from 'src/store/ui/detectionSettings/selectors';
import {
    clearSelectedWorkspaceObjectIds,
    ObjectsTabSelection,
    setObjectsPanelTabSelection,
} from 'src/store/ui/projectPanel';
import { DetectionSettingsPanel } from 'src/visualization/DetectionSettingsPanel/DetectionSettingsPanel';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import {
    DELETE_MODEL_TITLE,
    getDeleteModelMessage,
    INVALID_NEW_MODEL_NAME_MESSAGE,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ModelListEntry({
    index,
    model,
    setShouldDisableAddModelButton,
    disableMenu,
    lastItem,
}: {
    index: number;
    model: GtmModel;
    setShouldDisableAddModelButton: Dispatch<SetStateAction<boolean>>;
    disableMenu?: boolean;
    lastItem?: boolean;
}) {
    const applyTrace = useTrace(`models-list-entry-${index}`);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElDetectionSettings, setAnchorElDetectionSettings] = useState<null | HTMLElement>(
        null,
    );
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState('');
    const [showingDetectionSettings, setShowingDetectionSettings] = useState(false);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const currentProjectModelNames = useAppSelector(selectCurrentProjectModelNames);
    const isNewNameInvalid = useMemo(() => {
        let modelNames = currentProjectModelNames;
        if (isRenaming) {
            modelNames = modelNames.filter((name) => name !== model.name);
        }
        return modelNames.includes(newName.trim());
    }, [currentProjectModelNames, newName, isRenaming]);
    const [modelToDelete, setModelToDelete] = useState<GtmModel | null>(null);
    const { switchCurrentModel } = useConglomerateActionManager();
    const { syncProject } = useProjectSynchronizer();
    const models = useAppSelector(selectCurrentProjectModels);
    const isAnalyticalModel = isGtmAnalyticalModel(models?.[index]);
    const shouldDisableRenameConfirm = isNewNameInvalid || newName.trim() === '';

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);

        // When we open model menu, ensure that this model is selected
        switchSelectedModel(index);
    };

    const handleOpenDetectionSettings = (event: MouseEvent<HTMLElement>) => {
        const modelSettings = (models[index] as GtmAnalyticalModel).analyticalModelSettings;
        const detectionSettings = detectionSettingsFromAnalyticalModelSettings(modelSettings);
        dispatch(setAllDetectionSettings(detectionSettings));
        setAnchorElDetectionSettings(event.currentTarget);
        setShowingDetectionSettings(!showingDetectionSettings);

        // When we open analytical model settings, automatically select this model
        switchSelectedModel(index);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseDS = () => {
        setShowingDetectionSettings(!showingDetectionSettings);
        setAnchorElDetectionSettings(null);
    };

    const handleRenameOnClick = () => {
        setShouldDisableAddModelButton(true);
        setIsRenaming(true);
        setNewName(model.name ?? '');
        handleCloseMenu();
    };

    const handleRenameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleConfirmRename = () => {
        dispatch(setCurrentModelName(newName.trim()));
        syncProject();

        setNewName('');
        setIsRenaming(false);
        setShouldDisableAddModelButton(false);
    };

    const handleCancelRename = () => {
        setNewName('');
        setIsRenaming(false);
        setShouldDisableAddModelButton(false);
    };

    const handleRenameOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !shouldDisableRenameConfirm) {
            handleConfirmRename();
        } else if (event.key === 'Escape') {
            handleCancelRename();
        }
    };

    const handleDeleteOnClick = () => {
        setModelToDelete(model);
    };

    const switchSelectedModel = (entryIndex: number) => {
        if (entryIndex === selectedModelIndex) {
            return;
        }

        switchCurrentModel(index);
        dispatch(deselectCurrentModelSelectedObject());
        dispatch(setObjectsPanelTabSelection(ObjectsTabSelection.ModelObjects));
        dispatch(clearSelectedWorkspaceObjectIds());
    };
    const createOnClickHandler = (entryIndex: number) => () => {
        switchSelectedModel(entryIndex);
    };

    const handleDeleteConfirm = () => {
        if (modelToDelete) {
            dispatch(deleteModelInCurrentProject(modelToDelete));
            syncProject();
            if (index === selectedModelIndex) {
                switchCurrentModel(0);
            }
        }

        setModelToDelete(null);
    };

    const handleDeleteCancel = () => {
        setModelToDelete(null);
    };

    return (
        <>
            <ListItem disableGutters disablePadding divider={!lastItem}>
                <ListItemButton
                    selected={index === selectedModelIndex}
                    onClick={createOnClickHandler(index)}
                    sx={{ padding: theme.spacing(0, 0, 0, 2) }}
                >
                    {isRenaming ? (
                        <Stack
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%',
                            }}
                            direction="row"
                            p={theme.spacing(1, 1, 1, 0)}
                        >
                            <TextField
                                automation-id={applyTrace('rename-text-field')}
                                size="small"
                                variant="standard"
                                value={newName}
                                onChange={handleRenameOnChange}
                                onKeyUp={handleRenameOnKeyUp}
                                InputProps={{ sx: { fontSize: '12px' } }}
                                error={isNewNameInvalid}
                                helperText={
                                    isNewNameInvalid ? INVALID_NEW_MODEL_NAME_MESSAGE : null
                                }
                            />
                            <Stack direction="row">
                                <IconButton
                                    automation-id={applyTrace('rename-confirm-button')}
                                    sx={{ paddingRight: theme.spacing(1) }}
                                    size="small"
                                    disabled={shouldDisableRenameConfirm}
                                    onClick={handleConfirmRename}
                                >
                                    <CheckIcon
                                        color={shouldDisableRenameConfirm ? 'disabled' : 'primary'}
                                    />
                                </IconButton>
                                <IconButton
                                    automation-id={applyTrace('rename-cancel-button')}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    onClick={handleCancelRename}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    ) : (
                        <ListItemText
                            disableTypography
                            primary={
                                <OverflowTooltipTypography
                                    variant="body2"
                                    sx={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {model.name}
                                </OverflowTooltipTypography>
                            }
                        />
                    )}
                    {!isRenaming && isAnalyticalModel && (
                        <IconButton
                            automation-id={applyTrace('detection-settings-menu-button')}
                            size="small"
                            onClick={handleOpenDetectionSettings}
                            sx={{
                                backgroundColor: showingDetectionSettings
                                    ? theme.palette.action.focus
                                    : undefined,
                            }}
                        >
                            <ShowChartOutlined />
                        </IconButton>
                    )}
                    {!isRenaming && (
                        <IconButton
                            automation-id={applyTrace('menu-button')}
                            disabled={disableMenu}
                            size="small"
                            onClick={handleOpenMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </ListItemButton>
            </ListItem>
            <PanelItemMenu
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                onDelete={models.length > 1 ? handleDeleteOnClick : undefined}
                onRename={handleRenameOnClick}
            />

            <DeleteDialog
                title={DELETE_MODEL_TITLE}
                shouldOpen={Boolean(modelToDelete)}
                message={getDeleteModelMessage(modelToDelete?.name ?? '')}
                handleCancel={handleDeleteCancel}
                handleDelete={handleDeleteConfirm}
            />

            {anchorElDetectionSettings && (
                <DetectionSettingsPanel
                    modelIndex={index}
                    anchorEl={anchorElDetectionSettings}
                    onClose={handleCloseDS}
                />
            )}
        </>
    );
}
